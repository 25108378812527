<template>
  <DocumentBase :config="documentConfig" />
</template>

<script>
import DocumentBase from './components/DocumentBase.vue';

export default {
  name: 'DocumentManager',
  components: {
    DocumentBase
  },
  data() {
    return {
      documentConfig: {
        title: 'Bono / documentos',
        documentNameLabel: 'Nombre del documento',
        documentNamePlaceholder: 'Ingrese el nombre del documento',
        documentType: 'gerentes',
        document: 'custom',
        area: 'relaciones-laborales',
        redirectRoute: 'marginCommisions',
        useMultiSign: true,
        switches: [
          {
            model: 'is_multifirmas',
            label: 'Documento multifirmas'
          },
          {
            model: 'is_external',
            label: 'Documento externo'
          },
          {
            model: 'send_pbot',
            label: 'Enviar documento PBOT'
          },
          {
            model: 'send_duva',
            label: 'Enviar copia por correo electrónico'
          },
          {
            model: 'coordinator_signature',
            label: 'Agregar firma de Gerente de Relaciones Laborales'
          }
        ]
      }
    };
  }
};
</script>
